import { api, http } from "@/utils/api";
import { pick } from "@/utils/helpers";

export function addPayExternalAccount(params, recaptchaToken) {
  const whitelist =
    "name,type,data,logo,privatelyOwned,exchangeWallet,token,publicToken,accountId,accountName,code,state,network";
  return api.post(
    `/pay-external-accounts?recaptchaToken=${recaptchaToken}`,
    pick(params, whitelist)
  );
}

export function addBlockChainWallet(recaptchaToken) {
  return api.post(
    `/pay-external-accounts/verify-wallet?recaptchaToken=${recaptchaToken}`
  );
}

export function addWithdrawAccount(params, recaptchaToken) {
  return api.post(
    `/pay-external-accounts/contractor/fiat?recaptchaToken=${recaptchaToken}`,
    params
  );
}

export function getRequirements(country, beneficiaryType, currency) {
  return api.get(
    `/pay-external-accounts/requirements?beneficiaryType=${beneficiaryType}&bankCountry=${country}${
      currency ? `&currency=${currency}` : ""
    }`
  );
}

export function getForexCountries() {
  return api.get(`/pay-external-accounts/countries/forex`);
}

export function getUsdCountries() {
  return api.get(`/pay-external-accounts/countries/usd`);
}

export function getWithdrawOptions() {
  return api.get(`/pay-external-accounts/options`);
}

export function getCountryCurrencies(country) {
  return api.get(`/pay-external-accounts/country/${country}/currencies`);
}

export function getNGNBanks() {
  return api.get(`/pay-external-accounts/banks/NGN`);
}

export function updateWithdrawAccount(id, data, recaptchaToken) {
  return api.put(
    `/pay-external-accounts/contractor/fiat/${id}?recaptchaToken=${recaptchaToken}`,
    data
  );
}

export function getAccountByTask(taskId) {
  return api.get(`/pay-external-accounts/tasks/account?taskId=${taskId}`);
}

export function coinbaseOAuthLink(state) {
  const runtimeConfig = useRuntimeConfig();
  const COINBASE_REDIRECT_URI =
    runtimeConfig.public.NUXT_ENV_COINBASE_REDIRECT_URI;
  const COINBASE_CLIENT_ID = runtimeConfig.public.NUXT_ENV_COINBASE_CLIENT_ID;

  return `https://www.coinbase.com/oauth/authorize?client_id=${COINBASE_CLIENT_ID}&redirect_uri=${COINBASE_REDIRECT_URI}&state=${state}&response_type=code&account=all&scope=wallet:addresses:read,wallet:accounts:read,wallet:user:email`;
}

export function coinbaseOAuthLinkForAccounts() {
  return api.get("/pay-external-accounts/coinbase-auth/accounts");
}

export function plaidGetLinkToken() {
  return api.get("/pay-external-accounts/plaid/link");
}

export function plaidGetAccountInfo(
  publicToken,
  accountId,
  countryCode,
  recaptchaToken
) {
  return api.post(
    `/pay-external-accounts/plaid/account?recaptchaToken=${recaptchaToken}`,
    { publicToken, accountId, countryCode }
  );
}

export function refreshPlaidAccount(body) {
  return api.post("/pay-external-accounts/plaid/link-renew-public-token", body);
}

export function plaidAddCircle(params) {
  const whitelist = "publicToken,accountId,accountName";
  return api.post(
    "/pay-external-accounts/plaid/circle",
    pick(params, whitelist)
  );
}

export function getAccounts(params) {
  return api.get("/pay-external-accounts");
}

export function getAccount(id) {
  console.log(`Fetching external account with id: ${id}`);
  return api.get(`/pay-external-accounts/${id}?cb_address=true`);
}

export function updateAccount(id, name, recaptchaToken) {
  return api.put(
    `/pay-external-accounts/${id}?recaptchaToken=${recaptchaToken}`,
    { name }
  );
}

export function getDowntime(id) {
  return api.get(`/pay-external-accounts/${id}/downtime`);
}

export function deleteAccount(id, recaptchaToken) {
  return api.delete(
    `/pay-external-accounts/${id}?recaptchaToken=${recaptchaToken}`
  );
}

export function exchangeCoinbaseCode(code, state) {
  return api.get(
    `/pay-external-accounts/coinbase-auth/exchange/?code=${code}&state=${state}`
  );
}

export function hasCoinbaseAccount() {
  return http.get("/pay-external-accounts/coinbase/accounts");
}

export function getIBANCheck(iban, recaptchaToken) {
  return api.post(
    `/pay-external-accounts/iban-check?recaptchaToken=${recaptchaToken}`,
    { iban }
  );
}

export function getExternalAccountInfo(data) {
  const whitelist = "clabe,iban,routingnumber,country";
  const params = pick(data, whitelist);
  if (params.routingnumber) {
    params.routingNumber = params.routingnumber;
    delete params.routingnumber;
  }
  return api.post(`/pay-external-accounts/bank/info`, data);
}

export function routingNumberLookup(rn, recaptchaToken) {
  return api.post(
    `/pay-external-accounts/routing-number-lookup?recaptchaToken=${recaptchaToken}`,
    {
      routingNumber: rn,
    }
  );
}

export function getFee(id) {
  return api.get(`/pay-external-accounts/${id}/fee`);
}

export function availableCurrencies(country) {
  return api.get(`/pay-external-accounts/currencies/countries/${country}`);
}
